import {
    axios
} from '@/utils/request'

//样本户移民情况统计

// 获取列表数据
export function _SampleHouseholdStatisticPage(params) {
    return axios({
        url: '/SampleHousehold/StatisticPage',
        method: 'get',
        params,
    })
}

// 新增
export function _SampleHouseholdStatisticAdd(params) {
    return axios({
        url: '/SampleHousehold/StatisticAdd',
        method: 'post',
        data: params,
    })
}

// 编辑
export function _SampleHouseholdStatisticEdit(params) {
    return axios({
        url: '/SampleHousehold/StatisticEdit',
        method: 'post',
        data: params,
    })
}


// 删除
export function _SampleHouseholdStatisticDelete(params) {
    return axios({
        url: '/SampleHousehold/StatisticDelete',
        method: 'post',
        data: params,
    })
}



//详情
export function _SampleHouseholdStatisticInfo(params) {
    return axios({
        url: '/SampleHousehold/StatisticInfo',
        method: 'get',
        params,
    })
}


// excel导入
export function _SampleHouseholdStatisticImport(params) {
    return axios({
        url: '/SampleHousehold/StatisticImport',
        method: 'get',
        params,
    })
}


// excel导出
export function _SampleHouseholdStatisticExport(params) {
    return axios({
        url: '/SampleHousehold/StatisticExport',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





